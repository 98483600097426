.EnterTainment {
  background-color: #ffffff;
  width: 100%;
  .mainContentDiv {
    padding: .4rem 1rem;
    width: 100%;
    .top {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .text1 {
        color: #000;
        font-size: 28px;
        font-weight: 600;
      }

      .text2 {
        font-size: 18px;
        font-weight: 600;
        margin-top: 12px;
      }

      .bottomLine {
        height: 3px;
        width: 60px;
        background-color: #c60000;
        margin-top: 12px;
      }
    }

    .top2 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 20px;
    }

    .top2Left {
      width: 48%;
      margin-top: 25px;
      .gsjs1 {
        width: 100%;
      }
    }

    .top2Right {
      width: 48%;

      .top2Righttop {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .active {
          width: 50%;
          height: .5rem;
          color: #ffffff;
          font-size: .18rem;
          background-color: #c60000;
          line-height: .5rem;
        }

        .noActive {
          width: 50%;
          height: .5rem;
          color: #404040;
          font-size: .18rem;
          background-color: #efefef;
          line-height: .5rem;
        }
      }
    }
  }


}
.qiyerongyuCon{
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  .qiyerySwip{
    width: 80%;
  }
}
#dify-chatbot-bubble-button {
  display: none;
  background-color: #1C64F2 !important;

}