.game{
  background-color: #fff;
  .productList{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 80px 60px;
    
    .chanpinLink{
      display: block;
      width: 20% !important;
      .chanpin{
        width: 100%;
      }
    }
  }
}