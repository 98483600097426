.platForm {
  background-color: #fff;
  height: 100%;
  .mainContentDiv {
    padding: .4rem 1rem;
    .title1Img{
      width: 100%;
    }
    .top {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .text1 {
        color: #000;
        font-size: 28px;
        font-weight: 600;
      }

      .text2 {
        font-size: 22px;
        font-weight: 600;
        margin-top: 12px;
        text-align: left;
      }

      .bottomLine {
        height: 3px;
        width: 60px;
        background-color: #c60000;
        margin-top: 12px;
      }
    }

    .tabCon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      padding: 0 80px;
      .chanyeConActive {
        width: 13.5%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .chanyeConImg {
          width: 100%;
        }

        .chanyeContext {
          width: 100%;
          height: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: #c60000;
          color: #fff;
        }

        .chanyeConline {
          width: 40%;
          height: 4px;
          background-color: #c60000;
          margin-top: 20px;
        }
      }

      .chanyeCon {
        width: 13.5%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .chanyeConImg {
          width: 100%;
        }

        .chanyeContext {
          width: 100%;
          height: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: #efefef;
          color: #585858;
        }

        .chanyeConline {
          width: 40%;
          height: 4px;
          background-color: #ffffff;
          margin-top: 20px;
        }
      }

    }
  }

  .blackBlockPlat {
    background-color: #000;
    padding-top: 1.25rem;
    padding-bottom: 1.06rem;

    .ESTABLISHED {
      font-size: 0.15rem;
      color: #fff;
      // margin-top: 1.25rem;
      font-family: 'Centaur-Regular';
    }

    .in2013 {
      /* font-size: 'LucidaBright'; */
      color: #fff;
      font-size: .18rem;
      margin-bottom: .2rem;

      .in {
        font-size: .21rem;
        font-weight: 600;
        font-family: 'Edwardian-Script-ITC-Regular';
        margin-right: .04rem;
      }

      .n2013 {
        font-size: 'LucidaBright-Italic';
        color: #fff;
        font-size: .18rem;
      }

    }

    .fn {
      font-size: 2.03rem;
      color: #fff;
      height: 1.83rem;
      ;
      line-height: 1.83rem;
      font-family: PalatinoLinotype-Italic;
    }

    .yxpt {
      font-size: .72rem;
      font-family: NSimSun;
      color: #fff;
    }

    .cjyxpt {
      font-family: Fontquan;
      color: #fff;
      font-size: .59rem;
    }

    .cjyxptx {
      font-family: YouYuan;
      color: #fff;
      font-size: .26rem;
      margin-top: .25rem;
    }
  }

  .blockCon {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 .8rem .58rem;
  }

  .ogs {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    font-size: .26rem;
    padding: .51rem 0 .51rem .8rem;

    .og {
      font-family: Mingliu;
    }

    .s {
      font-family: NSimSun;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
    }
  }

  .zBlock {
    background-color: #BFBFBB;
    margin-top: 1.11rem;
    padding-top: .3rem;
    padding-bottom: .48rem;

    .GAME {
      font-size: 2.03rem;
      font-family: Felix-Titling-Regular;
    }

    .qhz {
      font-size: .59rem;
      color: #8E8E89;
      font-family: Fontquan;
    }

    .qz {
      color: #000;
    }

    .hoverStyle:hover {
      color: #000;
    }

    .weHave {
      font-size: .26rem;
      color: #8E8E8E;
      margin-top: .24rem;
      font-family: YouYuan;
    }
  }
}

.jsj {
  height: 3rem;
}

.img6541 {
  height: 7.88rem;
}

.img6542 {
  height: 2.88rem;
}