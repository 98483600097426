.Digital {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .imageHovorList {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    background-color: #fff;

    .imageHovor1 {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/noActiveImg1.png');
    }

    .imageHovor1:hover {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/activeImg1.png');
    }

    .imageHovor2 {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/noActiveImg2.png');
    }

    .imageHovor2:hover {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/activeImg2.png');
    }

    .imageHovor3 {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/noActiveImg3.png');
    }

    .imageHovor3:hover {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/activeImg3.png');
    }

    .imageHovor4 {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/noActiveImg4.png');
    }

    .imageHovor4:hover {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/activeImg4.png');
    }

    .imageHovor5 {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/noActiveImg5.png');
    }

    .imageHovor5:hover {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/activeImg5.png');
    }

    .imageHovor6 {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/noActiveImg6.png');
    }

    .imageHovor6:hover {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/activeImg6.png');
    }
    .imageHovor7 {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/noActiveImg7.png');
    }

    .imageHovor7:hover {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/activeImg7.png');
    }
    .imageHovor8 {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/noActiveImg8.png');
    }

    .imageHovor8:hover {
      width: 1.95rem;
      height: 2.99rem;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url('../../assets/yanxuan/chuangxinjidi/activeImg8.png');
    }
  }

  .loucengCon {
    position: relative;
    height: 8.99rem;

    .consk {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }

  .loucengBtnCon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    .loucengBtnActive {
      width: 33.33%;
      height: .6rem;
      line-height: .6rem;
      background-color: #B10000;
      color: #fff;
      text-align: center;
      font-size: 20px;
    }

    .loucengBtn {
      width: 33.33%;
      height: .6rem;
      line-height: .6rem;
      background-color: #ECECEC;
      color: #444;
      font-size: 20px;
      text-align: center;
    }
  }
.videoCon{
  width: 45%;
  padding: 30px 80px 0px;
}
.videoCon1{
  width: 45%;
  padding: 0 50px 0px;
}
  .checkMore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .4rem;
    .checkMoreBtn{
      width: 1.68rem;
      height: .43rem;
    }
  }

  .jdrxT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .jdrx {
      font-size: .4rem;
      font-weight: 600;
      color: #ad1f1f;
    }

    .news {
      font-size: .25rem;
      font-weight: 500;
      color: #71716f;
    }

    .lineA {
      background-color: #eca118;
      height: 6px;
      width: 30px;
      margin-bottom: 40px;
      border-radius: 2px;
    }
  }

  .newCo {
    display: flex;
    padding: 0 1.8rem;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;

    .NewsCon {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: self-start;

      
    }
  }
}
.videotext{
  display: flex;
  justify-content: center;
  align-items: center;
}
.textCOn{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 45%;
  margin-top: 30px;
}
.text11{
  width: 100%;
  font-size: 20px;
  text-align: left;
  margin-bottom: 5px;
  font-weight: 500;
  text-indent:40px
}