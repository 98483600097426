.aboutUs {}

.articlContent {
  font-size: .3rem;
  text-align: left;
  img{
    width: 70%;
    margin-bottom: 30px;
  }
}
.ACon{
  padding: 12px 2rem .6rem;
}
.titileK {
  font-size: .4rem;
  font-weight: 600;
  margin-top: 20px;
}

.timeC {
  font-size: .2rem;
  color: #00000060;
  margin-top: 20px;
  margin-bottom: 20px;
}