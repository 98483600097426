.newsItem{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dotted #00000060;
  padding: 12px 0;
  cursor:pointer;
  .info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .newsTitle{
      font-size: 22px;
      font-weight: 600;
      text-align: left;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .newsDesc{
      font-size: 16px;
      color: #00000060;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      margin-top: 10px;
    }
  }
  .dateCon{
    padding-left: 40px;
    .date1{
      font-size: 30px;
      font-weight: 600;
    }
    .date2{
      font-size: 20px;
      font-weight: 600;
    }
  }
}