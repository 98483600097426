.BottomViewCon{
  background-color: #b10013;
  width: 100%;

}
.BottomView{
  background-color: #b10013;
  padding: .6rem;
  display: flex;
  justify-content: space-around;
  color: #fff;
  width: 100%;
}
.left{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
.lxwm{
  font-size: 26px;
}
.gs{
  font-size: 18px;
  margin-top: 12px;
}
.right{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
.codeName1{
  margin-left: 20px;
}
.codeImg{
  width: 1.5rem;
}
.RELATIONS{
  color: #fff;
  font-size: .25rem;
  margin-bottom: 2.11rem;
  font-family: Book-Antiqua-Regular;
}
.bottomText1{
  font-size: .8rem;
  color: #fff;
  margin-bottom: .98rem;
  font-family: Colonna;
}
.pph{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: .97rem;
}
.pphItemT{
  color: #fff;
  font-size: .25rem;
  font-family: Centaur-Regular;
  height: .7rem;
  /* width: 3.29rem; */
  padding: 0 0.3rem;
  line-height: .7rem;
  text-align: center;
  border: #fff 1px solid;
  overflow: hidden;
}
.pphItemB{
  font-family: DengXian;
  font-size: .24rem;
  color: #fff;
  margin-top: .2rem;
}
.happy{
  color: #fff;
  font-size: .32rem;
  font-weight: 500;
  margin-bottom: .18rem;
  font-family: DengXian;
}
.funny{
  font-size: .24rem;
  font-family: DengXian;
  color: #fff;
  font-weight:'Regular';
}
.connectUs{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.91rem;
}
.firstLine{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.EXPLORE{
  color: #fff;
  font-size: .15rem;
  font-family: Calibri-Regular;
}
.TITTLE{
  color: #fff;
  font-size: .15rem;
  font-family: Calibri-Regular;
  margin-left: .79rem;
}
.secondLine{
  height: 1.62rem;
  border-top: 1px solid #8E8E8E;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #8E8E8E;
}
.secondLine:hover{
  height: 1.62rem;
  border-top: 1px solid #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}
.e{
  font-size: .8rem;
  font-family: Calibri-Regular;
  height: .8rem;
  width: .2rem;
  line-height: .8rem;
}
.t{
  font-size: .8rem;
  font-family: Perpetua-Titling-MT-Light;
  height: .8rem;
  line-height: .8rem;
  margin-left: .79rem;
}
.th{
  font-size: .7rem;
  font-family: NSimSun;
  height: .8rem;
  line-height: .8rem;
  margin-left: .3rem;
  margin-top: -.15rem;
}
.contactTool{
  margin-top: .75rem;
  padding-left: 1.26rem;
}
.beianCon{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.toolCon{
  /* margin-left: 1.26rem; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 5.49rem;
  position: relative;
  width: auto;
}
.toolCon2{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: auto;
}
.di{
  width: .7rem;
}
.toolCon1{
  /* margin-left: 1.26rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 5.49rem;
  position: relative;
}
.code{
  position: absolute;
  z-index: 100;
  top: -3rem;
  left: calc(50% - 2.5rem);
  width: 3rem;
  height: 3rem;
}
.tool{
  color: #fff;
  font-size: .35rem;
  font-family: Bookman-Old-Style-Regular;
}
.beian{
  font-size: 18px;
  color: #8E8E8E;
  margin-top: 30px;
}
.beian{
  padding-bottom: 12px;
  color: #fff;
  font-size: .2rem;
  display: flex;
  justify-content: center;
}
.text-color{
  color: #fff;
  padding-left: .3rem;
  padding-right: .3rem;
}
.right-color{
  color: #fff;
  padding-bottom: 20px;
  font-size: .2rem;
}