.swiperCon1{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.btnCo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  .leftS{
    margin-right: 40px;
  }
}
.swiperItem{
  width: 100%;
  height: 9.69rem;
}
/* .swiper-pagination{
  bottom: 3.13rem !important;
} */
.swiper-pagination-bullet{
  background-color: #929292 !important;
}
.swiper-pagination-bullet-active{
  background-color: #FFFFFF !important;
}
.rongyustayle{
  box-shadow:  10px 10px 5px #00000040;
  width: 100%;
}
.bigBck{
  height: 5rem;
  width: 100%;
  position: relative;
  background-color: #ae000e;
}
.bigImg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.litiImg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 160;
}
.img2Con{
  height: 75%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoCon{
  background-color: #a8a8a8;
  height: 25%;
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  align-items: center;
}
.infoCon1{
  background-color: #ae000e;
  height: 25%;
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  align-items: center;
}
.more{
  width: 50px;
  height: 50px;
}
.namec{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
}
.namex1{
  color: #FFFFFF;
  font-size: .35rem;
  font-weight: 500;
}
.namex2{
  color: #FFFFFF;
  font-size: .18rem;
}