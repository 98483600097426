.Digital{
  background-color: #fff;
  .learnCon{
    // padding: 0 5%;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    .alink{
      display: block;
      width: 23%;
      .all{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        .paozhi{
          width: 100%;
        }
        .learn{
          margin-top: 20px;
        }
      }
    }
    
  }
  .loucengCon{
    position: relative;
    .consk{
      position: absolute;
      z-index: 9;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
  }
  .loucengBtnCon{
    display: flex;
    justify-content: space-around;
    align-items: center;
    .loucengBtnActive{
      width: 33.33%;
      height: .6rem;
      line-height: .6rem;
      background-color: #B10000;
      color: #fff;
      text-align: center;
      font-size: 20px;
    }
    .loucengBtn{
      width: 33.33%;
      height: .6rem;
      line-height: .6rem;
      background-color: #ECECEC;
      color: #444;
      font-size: 20px;
      text-align: center;
    }
  }
  .spaceShow{
    background-color: #ECECEC;
    width: 100%;
    padding: 60px 40px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ts1{
      color: #a80000;
      font-size: .4rem;
      font-weight: 600;
    }
    .ts2{
      color: #848484;
      font-size: .26rem;
    }
    .ts5{
      height: 4px;
      border-radius: 1px;
      width: 25px;
      background-color: #ed9e00;
    }
    .ts3{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .tsw{
        height: 40px;
        padding: 0 12px;
        line-height: 40px;
        font-size: 28px;
        color: #fff;
        background-color: #a80000;
        margin:0 10px;
      }
      .tsd{
        height: 40px;
        padding: 0 12px;
        line-height: 40px;
        font-size: 28px;
        color: #a80000;
        border: 1px solid #a80000;
        margin:0 10px;
      }
    }
  }
}