.swiperCon1{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.btnCo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  .leftS{
    margin-right: 40px;
  }
}
.swiperItem{
  width: 100%;
  height: 9.69rem;
}
/* .swiper-pagination{
  bottom: 3.13rem !important;
} */
.swiper-pagination-bullet{
  background-color: #929292 !important;
}
.swiper-pagination-bullet-active{
  background-color: #FFFFFF !important;
}
.rongyustayle{
  box-shadow:  10px 10px 5px #00000040;
  width: 100%;
}