.AiAssistant1 {
  z-index: 9999;
  background: transparent;
  .Aicon {
    position: fixed;
    transform: translateX(-50%);
    left: 50%;
    bottom: 120px;
    width: 867px;
    height: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background: transparent;
    border-radius: 15px;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    .AIconQ{
      width: 100%;
      height: 100%;
      min-height: 500px;
      padding-bottom: 30px;
      border-radius: 15px;
      background-color: #fff;
    }
  }
  .AiconNone {
    width: 80%;
    height: 60%;
    display: none;
  }
}
.closeCon{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: #33333323 1px solid;
  
  .closeCon1{
    width: 100%;
    // max-width: 720px;
    padding: 0 .18rem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    // padding-top: 20px;
    .zhanwei{
      width: 30px;
      height: 30px;
    }
    .zxkf{
      font-size: 24px;
      font-weight: 600;
    }
    .closeImg{
      width: 30px;
      height: 30px;
      cursor: pointer;
      // position: absolute;
      // bottom: -20px;
      // right: 0;
    }
  }
}
.AiAssistant2{
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: transparent;
}

.enterBar {
  height: 80px;
  width: 600px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 40px;
  position: fixed;
  transform: translateX(-50%);
  bottom: 120px;
  z-index: 9999;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
  .holderText {
    margin-left: 20px;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
    // -webkit-animation: holderTextPackUp 5s;
  }
  .holderTextDisplayNone{
    display: none;
  }
}

.imgcon {
  position: relative;
  background-image: url('../../assets/movieImg.gif');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cef7f8;
  border-radius: 40px;
}
.imgconRight {
  position: fixed;
  right: 10px;
  cursor: pointer;
  bottom: 120px;
  background-image: url('../../assets/movieImg.gif');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cef7f8;
  border-radius: 40px;
  z-index: 9999;
}
.imgconRightDisplay {
  display: none;
  // position: absolute;
  // right: 10px;
  // bottom: 120px;
  // background-image: url('../../assets/movieImg.gif');
  // background-repeat: no-repeat;
  // background-size: 100%;
  // width: 80px;
  // height: 80px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // background-color: #cef7f8;
  // border-radius: 40px;
}
.aiImgBack {
  width: 80px;
  height: 80px;
}

.aiImgRobot {
  width: 40px;
  height: 40px;

}
.pt-8{
  padding-top: none !important;
}
.max-w-\[720px\]{
  max-width: none !important;
}