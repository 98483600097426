.blockT{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  .blockTI{
    color: #ac1213;
    font-size: .38rem;
    font-weight: 600;
  }
  .blockTitl{
    color: #7b7b79;
    font-size: .28rem;
  }
  .blockLine{
    height: 4px;
    border-radius: 1px;
    background-color: #eda00a;
    margin-top: 15px;
  }
}