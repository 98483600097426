.antui-notification {
  &-success {
    background: #cddc39;
    .ant-notification-notice-message,
    .ant-notification-notice-description {
      color: #1b5e20;
    }
    .ant-notification-notice-icon {
      color: tint(#cddc39);
    }
  }
  &-error {
    background: #e51c23;
    .ant-notification-notice-message,
    .ant-notification-notice-description {
      color: #ffebee;
    }
    .ant-notification-notice-icon {
      color: tint(#e51c23);
    }
  }
  &-warning {
    background: #ff9800;
    .ant-notification-notice-message,
    .ant-notification-notice-description {
      color: #fff3e0;
    }
    .ant-notification-notice-icon {
      color: tint(#ff9800);
    }
  }
  &-info {
    background: #2196f3;
    .ant-notification-notice-message,
    .ant-notification-notice-description {
      color: #e3f2fd;
    }
    .ant-notification-notice-icon {
      color: tint(#2196f3);
    }
  }
}
