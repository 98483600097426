.certItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  margin-bottom: 40px;

  .left {
    width: 8rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 30px;
    padding-bottom: 30px;

    .leftImg {
      width: 3rem;
      border: 12px solid #fff;
    }
  }

  .right {
    margin-left: 40px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;

    .certInfo {
      font-size: 18px;
      color: #00000070;
      height: 30px;
      line-height: 30px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
}
.BasicModal{
  height: 8rem;
  .bigImg{
    width: 80%;
  }
}