.aboutUs{
  .mainCOn{
    width: 100%;
    padding: 0 1.3rem;
    .top1{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 100px;
      margin-bottom: 50px;
      .top11{
        font-size: 50px;
        font-weight: 600;
      }
      .top12{
        font-size: 22px;
        color: #00000050;
        font-weight: 500;
        margin-top: 20px;
      }
      .top13{
        height: 5px;
        background-color: #cb3b3c;
        width: 110px;
        margin-top: 30px;
      }
    }
    .top2{
      display: flex;
      justify-content: space-between;
    }
    .left{
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
    }
    .right{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
}
