.cert {
  background-color: #fff;

  .searchblock {
    width: 100%;
    height: 8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url('../../assets/yanxuan/cert/certBack.png');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cyzs {
      color: #fff;
      width: 100%;
      text-align: center;
      font-size: .6rem;
      font-weight: 600;
    }

    .inputCon {
      width: 40%;
      height: .5rem;
      border: 3px solid #fff;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 5px;

      .searchKey {
        background-color: rgba(1, 1, 1, 0);
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: .3rem;
        padding-left: 12px;
      }
    }

    .searchBtnCon {
      width: 40%;
      height: .5rem;
      margin-top: 20px;
      .searchBtn {
        background-color: #c60000;
        height: 100%;
        color: #fff;
        font-size: .25rem;
      }
    }

  }

  .resultCon {
    background-color: #fff;

    .resultTop {
      height: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .cxjg {
        color: #00000090;
        font-size: .6rem;
      }

      .engLishTi {
        color: #00000060;
        font-size: .3rem;
      }

      .redLine {
        background-color: #c60000;
        height: 3px;
        width: 1rem;
        margin-top: 20px;
      }
    }

    .resultList {
      .emptyCon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;

        .emptyIcon {
          width: 3rem;
          height: 3rem;
        }

        .kong {
          font-size: 18px;
          color: #00000060;
        }
      }
    }
  }
}