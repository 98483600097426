.Digital{
  background-color: #fff;
  .loucengCon{
    position: relative;
    .consk{
      position: absolute;
      z-index: 9;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
  }
  .loucengBtnCon{
    display: flex;
    justify-content: space-around;
    align-items: center;
    .loucengBtnActive{
      width: 33.33%;
      height: .6rem;
      line-height: .6rem;
      background-color: #B10000;
      color: #fff;
      text-align: center;
      font-size: 20px;
    }
    .loucengBtn{
      width: 33.33%;
      height: .6rem;
      line-height: .6rem;
      background-color: #ECECEC;
      color: #444;
      font-size: 20px;
      text-align: center;
    }
  }

  .guoyiguan{
    background-color: #ECECEC;
    padding: 40px 60px;
    width: 100%;
  }
}