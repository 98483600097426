.newsItem2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  padding-bottom: 50px;
  .big {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .info{
      width: 100%;
      .ooo{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .newsTitle {
          font-size: 22px;
          font-weight: 600;
          text-align: left;
          text-overflow: ellipsis;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        .date1 {
          font-size: 18px;
          color: #00000060;
        }
      }
    }
   

    .newsDesc {
      font-size: 16px;
      color: #00000060;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      margin-top: 10px;
    }
    .dateCon {
      padding-left: 40px;
     
  
      .date2 {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .line3 {
    width: 100%;
    border-bottom: 1px dotted #00000060;
    margin-top: 30px;
  }
  
}